.restaurant-wrapper {
  height: 100vh;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: #ffffff;
}
.restaurant-wrapper-info {
  width: 100vw;
  max-width: 768px;
}

.restaurant-bottom-wrapper {
  top: 300px;
  width: 100vw;
  max-width: 768px;
  position: absolute;
  background-color: #ffffff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  min-height: calc(100vh - 300px);
  box-shadow: 0 1px 10px 3px rgb(0 0 0 / 0.08);
}
.restaurant-bottom-line-wrapper {
  width: 100vw;
  display: flex;
  max-width: 768px;
  padding-top: 17px;
  align-items: center;
  justify-content: center;
}
.restaurant-bottom-line {
  width: 66px;
  height: 5px;
  margin-bottom: 29px;
  border-radius: 10px;
  background-color: #eeeeee;
}

.restaurant-scroll-wrapper {
  width: 100vw;
  max-width: 768px;
  overflow: scroll;
  max-height: 90vh;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #ffffff;
}
.restaurant-scroll-wrapper::-webkit-scrollbar {
  display: none;
}

.restaurant-bottom-wrapper-info {
  width: 100%;
  height: 95%;
  padding: 17px;
}

.restaurant-row-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.restaurant-row-line-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}
.restaurant-text {
  color: rgb(0 0 0 / 0.8);
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
}
.restaurant-rating-text {
  color: #414141;
  font-size: 12px;
}
.restaurant-type-text {
  color: #b0bbc7;
  font-size: 14px;
  line-height: 17px;
  margin-top: 9px;
  margin-bottom: 15px;
}
.restaurant-address-text {
  color: rgb(0 0 0 / 0.8);
  font-size: 12px;
  line-height: 15px;
}

.restaurant-header-margin {
  margin-top: 22px;
}

/* restaurant-menu */
.restaurant-menu-wrapper {
  width: 100%;
  display: flex;
  padding: 17px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.restaurant-menu-header {
  color: rgb(0 0 0 / 0.8);
  font-size: 14px;
  font-weight: 600;
}
.restaurant-menu-search {
  width: 100%;
  max-width: 275px;
  flex-direction: row;
}

.restaurant-header-text {
  color: rgb(0 0 0 / 0.8);
  font-size: 14px;
}
.restaurant-see-all-text {
  color: #b0bbc7;
  font-size: 12px;
}

.restaurant-item-margin {
  margin-top: 12px;
}
.restaurant-item-wrapper {
  max-width: 232px;
  max-height: 232px;
  width: 25vw;
  height: 25vw;
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.08);
}

.restaurant-column-line-wrapper {
  /* width: 75%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.restaurant-item-text {
  color: #414141;
  font-size: 14px;
}

@media (min-width: 360px) {
  .restaurant-item-text {
    font-size: 16px;
  }
  .restaurant-item-type-text {
    font-size: 13px;
  }
}
@media (min-width: 500px) {
  .restaurant-item-text {
    font-size: 18px;
  }

  .restaurant-item-type-text {
    font-size: 16px;
  }
}

.restaurant-item-text-margin {
  margin-left: 16px;
}

.restaurant-item-type-text {
  color: #b0bbc7;
  font-size: 12px;
}

.restaurant-discount-text {
  color: #414141;
  /* font-size: 14px; */
  line-height: 17px;
  font-weight: bold;
}

.restaurant-menu-wrapper {
  padding: 17px;
}

.restaurant-menu-item-wrapper {
  width: 100%;
  overflow-x: auto;
}

.restaurant-menu-wrapper-info {
  gap: 12px;
  display: flex;
  overflow-x: auto;
  padding-left: 17px;
  flex-direction: row;
  padding-bottom: 14px;
  align-items: flex-start;
  padding-right: 17px;
  width: max-content;
}

.restaurant-menu-item {
  width: auto;
  color: rgb(0 0 0 / 0.8);
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  padding: 8px;
  border-radius: 7px;
  background-color: #ffd23c;
  text-transform: uppercase;
}

.restaurant-menu-select-wrapper {
  width: 100%;
  height: 28px;
  display: flex;
  padding-left: 17px;
  align-items: center;
  justify-content: flex-start;
  background-color: #f7f8fd;
  box-shadow: 0 2px 3px rgb(0 0 0 / 0.16);
}
.restaurant-menu-select-header-text {
  color: #b0bbc7;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
}

.restaurant-menu-selected-item-wrapper {
  width: 100%;
  padding: 17px;
}

.restaurant-cart-button {
  width: 100%;
  height: 44px;
  display: flex;
  padding-left: 17px;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  background-color: #fd9426;
  box-shadow: 0 2px 3px rgb(0 0 0 / 0.16);
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.bottom-sheet-wrapper {
  width: 100%;
  display: flex;
  padding-top: 24px;
  align-items: center;
  padding-bottom: 30px;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  overflow: unset;
  /* height: "340px"; */
  bottom: 'inherit';
  top: 'calc(100vh - 340px)';
  box-shadow: '0 4px 8px 1px rgb(0 0 0 / 0.8)';
}

.bottom-sheet-main-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bottom-sheet-cart-scroll::-webkit-scrollbar {
  width: 2px;
  height: 0px;
  background-color: #eeeeee;
}

.bottom-sheet-cart-scroll::-webkit-scrollbar-thumb {
  width: 2px;
  height: 5px;
  overflow-y: scroll;
  background-color: #fd9426;
}

.bottom-sheet-cart-scroll {
  width: 100%;
  display: flex;
  height: 200px;
  margin-top: 29px;
  overflow: scroll;
  align-items: center;
  flex-direction: column;
}

.bottom-sheet-restaurant-text {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  margin-bottom: 22px;
  color: rgb(0 0 0 / 0.8);
}

.bottom-sheet-line {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
}

.bottom-sheet-item-wrapper {
  width: 100%;
  display: flex;
  padding-left: 22px;
  align-items: center;
  padding-right: 22px;
  flex-direction: row;
  justify-content: space-between;
}

.bottom-sheet-item-text {
  font-size: 16px;
  line-height: 19px;
  color: rgb(0 0 0 / 0.8);
}

.bottom-sheet-price-text {
  font-size: 16px;
  line-height: 19px;
  color: rgb(0 0 0 / 0.8);
}

.bottom-sheet-item-line {
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #eeeeee;
}

.bottom-sheet-item-index {
  font-size: 16px;
  line-height: 19px;
  color: rgb(0 0 0 / 0.8);
  margin-right: 15px;
}

.bottom-sheet-button {
  width: 90%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  background-color: #fd9426;
  box-shadow: 0 2px 3px rgb(0 0 0 / 0.16);
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-top: 36px;
}

.bottom-sheet-empty-wrapper {
  width: 100%;
  height: 270px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 32px;
  padding-right: 32px;
}

.bottom-sheet-empty-text {
  font-size: 16px;
  margin-top: 40px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 8px;
  color: rgb(0 0 0 / 0.8);
}
.bottom-sheet-empty-title-text {
  font-size: 14px;
  line-height: 19px;
  color: rgb(0 0 0 / 0.8);
}
.bottom-sheet-empty-button {
  width: 100%;
  height: 44px;
  display: flex;
  font-size: 16px;
  margin-top: 100px;
  color: #ffffff;
  font-weight: bold;
  align-items: center;
  border-radius: 36px;
  justify-content: center;
  background-color: #fd9426;
  box-shadow: 0 2px 3px rgb(0 0 0 / 0.16);
}

.back-button-wrapper {
  width: 100%;
  display: flex;
  padding: 22px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.back-button {
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  border-radius: 22px;
  justify-content: center;
  background-color: #eff0f4;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.08);
}

/* MenuItemView */
.menu-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: #ffffff;
}
.menu-wrapper-info {
  width: 100%;
  max-width: 768px;
}
.menu-background-image-wrapper {
  width: 100%;
  height: 462px;
  display: flex;
  max-width: 768px;
  position: relative;
  overflow: 'hidden';
}
.menu-image {
  width: 100%;
  opacity: 0.2;
  height: 462px;
  max-width: 768px;
  position: absolute;
  object-fit: cover;
}
@media (max-width: 768px) {
  .menu-image {
    transform: scale(1, 1);
  }
}
@media (max-width: 576px) {
  .menu-image {
    transform: scale(1.5, 1.5);
  }
}
@media (max-width: 375px) {
  .menu-image {
    transform: scale(2, 2);
  }
}
.menu-bottom-wrapper {
  top: 0;
  width: 100%;
  height: 462px;
  max-width: 768px;
  position: absolute;
}
.menu-item-main-wrapper {
  padding-right: 22px;
  padding-left: 22px;
}
.menu-item-image-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.menu-item-image {
  width: 285px;
  height: 285px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-item-text {
  font-size: 24px;
  margin-top: 12px;
  font-weight: bold;
  line-height: 29px;
  text-align: center;
  color: rgb(0 0 0 / 0.8);
}
.menu-item-price {
  font-size: 18px;
  margin-top: 12px;
  font-weight: bold;
  line-height: 22px;
  color: rgb(0 0 0 / 0.8);
}
.menu-item-title-wrapper {
  padding-right: 22px;
  padding-left: 22px;
  margin-top: 30px;
}
.menu-item-description {
  font-size: 14px;
  line-height: 20px;
  color: #414141;
  margin-top: 24px;
  /* max-width: 300px; */
}

@media (min-width: 360px) {
  .menu-item-description {
    font-size: 16px;
    line-height: 24px;
    /* max-width: 300px; */
  }
}

@media (min-width: 540px) {
  .menu-item-description {
    font-size: 20px;
    line-height: 32px;
    /* max-width: 300px; */
  }
}

.menu-item-input-title {
  font-size: 14px;
  margin-top: 24px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: 16px;
  color: rgb(0 0 0 / 0.8);
}
.menu-item-row-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.quantity-text {
  font-size: 38px;
  font-weight: 600;
  line-height: 46px;
  color: rgb(0 0 0 / 0.8);
}
.quantity-wrapper {
  width: 92px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.quantity-button {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 1px 10px 3px rgb(0 0 0 / 0.08);
}
.cart-button-wrapper {
  width: 100%;
  padding: 22px;
  margin-top: 28px;
  background-color: #f0f3f7;
}
.cart-button {
  width: 100%;
  height: 44px;
  display: flex;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  padding-left: 17px;
  align-items: center;
  border-radius: 36px;
  justify-content: center;
  background-color: #fd9426;
  box-shadow: 0 2px 3px rgb(0 0 0 / 0.16);
}

.input-textarea-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.input-textarea-label {
  font-size: 14px;
  margin-top: 8px;
  color: #9d9b9b;
  margin-bottom: 8px;
}

.input-textarea {
  width: 100%;
  height: 97px;
  padding: 8px;
  font-size: 16px;
  color: #282828;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 10px 3px rgb(0 0 0 / 0.08);
}

.add-cart-button-wrapper {
  width: 100%;
  padding: 22px;
  margin-top: 28px;
  background-color: #f0f3f7;
}

.add-quantity-text {
  font-size: 38px;
  font-weight: 600;
  line-height: 46px;
  color: rgb(0 0 0 / 0.8);
}

.add-quantity-wrapper {
  width: 92px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.add-quantity-button {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 1px 10px 3px rgb(0 0 0 / 0.08);
}

@media (min-width: 768px) {
  .client-schedule-wrapper {
    align-self: center;
  }

  .client-table-action-wrapper {
    align-self: center;
    /*margin-left: 20px;*/
  }
}

@media (max-width: 768px) {
  .client-schedule-wrapper {
    align-self: center;
    margin-top: 10px;
  }

  .client-table-action-wrapper {
    align-self: center;
    /*margin-left: 20px;*/
  }
}

.search-input-wrapper {
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  border-radius: 32px;
  background-color: #ffffff;
  box-shadow: 0 1px 10px 3px rgb(0 0 0 / 0.08);
}

.search-input {
  width: 88%;
  fill: none;
  height: 36px;
  border: 0px solid;
  border-radius: 32px;
  padding-left: 12px;
  background-color: transparent;
}

.search-input:focus {
  outline: none;
  background-color: #ffffff;
}

.search-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.restaurant-image-loading {
  width: 100%;
  height: 300px;
  display: flex;
  max-width: 768px;
  align-items: center;
  justify-content: center;
}

.restaurant-details-loading-wrapper {
  width: 100vw;
  display: flex;
  max-width: 768px;
  align-items: center;
  justify-content: flex-start;
}

.restaurant-menu-loading-wrapper {
  width: 100vw;
  display: flex;
  max-width: 768px;
  padding-left: 17px;
  padding-right: 17px;
  align-items: center;
  justify-content: flex-start;
}

.restaurant-item-loading-wrapper {
  width: 100vw;
  display: flex;
  max-width: 768px;
  margin-top: 12px;
  padding-left: 17px;
  padding-right: 17px;
  align-items: center;
  justify-content: flex-start;
}

.restaurant-details-loading-wrapper {
  width: 100vw;
  display: flex;
  max-width: 768px;
  margin-top: 12px;
  align-items: center;
  justify-content: flex-start;
}

.price-variants > h3 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
}

.options {
  display: flex;
  font-size: 14px;
}

.options > div:hover {
  cursor: pointer;
}

.options > div {
  margin-right: 8px;
  padding: 4px 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.options > div.selected {
  background-color: rgb(253, 148, 38);
  color: white;
  border-color: rgb(253, 148, 38);
}
