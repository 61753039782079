.empty-wrapper {
  height: 100vh;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: #6D71F9;
}

.empty-wrapper-info {
  height: 95%;
  display: flex;
  max-width: 375px;
  padding-top: 104px;
  padding-bottom: 74px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.logo-wrapper {
  width: 140px;
}

.text-wrapper {
  display: flex;
  margin-top: 30px;
  align-items: center;
  flex-direction: column;
}

.title-text {
  font-size: 32px;
  color: #FFFFFF;
  margin-top: 30px;
  line-height: 38px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.desc-text {
  font-size: 18px;
  color: #FFFFFF;
  margin-top: 30px;
  font-weight: 400;
  line-height: 25px;
}

